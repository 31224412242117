import React from 'react';
import { Gatekeeper } from 'q3-admin/src/containers';
import { CircularProgress } from '@material-ui/core';
import { AuthContext } from 'q3-ui-permissions';
import HydrationFix from './HydrationFix';

export const withInit = (Component) => (props) => {
  const { state } = React.useContext(AuthContext);

  return (
    <HydrationFix>
      {state.init ? (
        <Component {...props} auth={state} />
      ) : (
        <CircularProgress />
      )}
    </HydrationFix>
  );
};

const IsLoggedIn = withInit(({ children }) => (
  <Gatekeeper redirectPathOnPublic="/login">
    {children}
  </Gatekeeper>
));

export const withLogin = (Component) => (props) =>
  (
    <IsLoggedIn>
      <Component {...props} />
    </IsLoggedIn>
  );

export default IsLoggedIn;
