import React from 'react';
import { get } from 'lodash';
import axios from 'axios';
import { Redirect, Link, navigate } from '@reach/router';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'q3-ui-locale';
import { Store } from 'q3-admin/src/containers';
import { isReadyForCreditCardProcessing } from 'gatsby-theme-gentek/src/components/helpers';
import { COLLECTIONS } from 'gatsby-theme-gentek/src/components/constants';
import Pay from '../components/Pay';
import TransactionLayout from '../components/TransactionLayout';
import Checkout from '../containers/Checkout';
import ConfirmationSvg from '../assets/payment.svg';
import { withLogin } from '../components/IsLoggedIn';
import { getCreditCardBrand } from '../utils';
import PayNote from '../components/PayNote';

const {
  ORDERS: { ENDPOINT },
} = COLLECTIONS;

const payForOrderById = (id) => (token) =>
  axios
    .post(`${ENDPOINT}/${id}/pay`, token)
    .then(() => navigate(`/account/orders/${id}`));

const PayForm = () => {
  const { data } = React.useContext(Store);
  const { t } = useTranslation();

  return (
    <TransactionLayout
      imgSrc={ConfirmationSvg}
      title="pay"
      buttonText="orders"
      buttonTo="/account/orders"
    >
      {isReadyForCreditCardProcessing(data) ? (
        <>
          <Typography>
            {t('descriptions:payForOrder')}
          </Typography>
          <Box mb={2}>
            <PayNote {...data} />
          </Box>
          <Pay
            service={payForOrderById(data.id)}
            options={{
              'card-number': {
                brands: getCreditCardBrand(
                  data.paymentOption,
                ),
              },
            }}
          />
        </>
      ) : (
        <Box mt={2}>
          <Typography>
            {t('descriptions:orderFailedToFetch')}
          </Typography>
          <Button
            size="large"
            to={`/account/orders/${data.id}`}
            component={Link}
            variant="contained"
            color="secondary"
          >
            {t('labels:viewOrder')}
          </Button>
        </Box>
      )}
    </TransactionLayout>
  );
};

const PayPage = withLogin((props) => {
  const orderId = new URLSearchParams(
    get(props, 'location.search'),
  ).get('orderId');

  return (
    <Checkout
      orderId={orderId}
      onEnter={({ order }) =>
        new Promise((resolve) => {
          if (!order || !order.confirmedOn) {
            navigate('/account/orders').then(() => {
              // resolve();
            });
          } else {
            resolve();
          }
        })
      }
    >
      <PayForm />
    </Checkout>
  );
});

export default PayPage;
