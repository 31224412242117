import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'q3-ui-locale';
import Typography from '@material-ui/core/Typography';
import { string } from '@lolly-labs/q3-ui-helpers';

const Total = ({ total }) => {
  const { t } = useTranslation('labels');
  return (
    <Box py={1}>
      <Typography variant="body2" component="p">
        <strong>{t('total')}</strong>
      </Typography>
      {string.toPrice(total)}
    </Box>
  );
};

Total.propTypes = {
  total: PropTypes.number,
};

Total.defaultProps = {
  total: 0,
};

export default Total;
